import { DownloadIcon } from 'lucide-react';
import Button from '~/core/ui/Button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '~/core/ui/Dropdown';
import Image from 'next/image';

export function FileDownloadButton (){
  const binaries = [
    {name: 'Linux (.deb)', imgsrc: '/assets/images/linux.png', url: 'https://github.com/nirops/moneypal-public-bin/releases/download/v0.2.3/moneypal-app_0.2.3_amd64.deb'},
    {name: 'Linux (.AppImage)', imgsrc: '/assets/images/linux.png', url: 'https://github.com/nirops/moneypal-public-bin/releases/download/v0.2.3/moneypal-app_0.2.3_amd64.AppImage'},
    {name: 'Windows (.msi)', imgsrc: '/assets/images/windows.svg', url: 'https://github.com/nirops/moneypal-public-bin/releases/download/v0.2.3/moneypal-app_0.2.3_x64_en-US.msi'},
    {name: 'Windows (.exe)', imgsrc: '/assets/images/windows.svg', url: 'https://github.com/nirops/moneypal-public-bin/releases/download/v0.2.3/moneypal-app_0.2.3_x64-setup.exe'},
    {name: 'Mac Intel (.dmg)', imgsrc: '/assets/images/mac.png', url: 'https://github.com/nirops/moneypal-public-bin/releases/download/v0.2.3/moneypal-app_0.2.3_aarch64.dmg'},
    {name: 'Mac M1 (.dmg)', imgsrc: '/assets/images/mac.png', url: 'https://github.com/nirops/moneypal-public-bin/releases/download/v0.2.3/moneypal-app_0.2.3_aarch64.dmg'},
  ]
  return (
    <DropdownMenu dir={'ltr'}>
      <DropdownMenuTrigger>
        <Button variant={'small'}
                className={'transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 active:[&>*]:translate-y-0.5 [&>*]:py-1 h-10 text-sm rounded-xl bg-transparent bg-gradient-to-r from-primary to-primary-600 text-white'}>
          Download
          <DownloadIcon size={14} className={'ml-2'}></DownloadIcon>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {binaries.map((bins) => {
          return (
            <DropdownMenuItem className={'cursor-pointer'}>
              <Image alt={bins.name} src={bins.imgsrc} className={'rounded-full mr-2'} width={'25'} height={'25'}></Image>
              {bins.name}
            </DropdownMenuItem>
          )
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  )

}
